
    <p class="widget-box--additional-info">
      {{ text.descriptionNewFeatures }}
    </p>

    <div class="widget-box--description">
      <p [innerHtml]="currentNewFeatureHtml"></p>

      <a class="widget-box--teaser-image"></a>
    </div>

    <a [href]="teaserWebsiteUrl" target="_blank">{{ text.learnAbout }}</a>
  